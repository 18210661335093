import { LayoutAuth } from '@/components/layouts'
import { LoginHeadline } from '@/components/login'
import { LoginButton } from '@/components/login-button'

export default () => {
	return (
		<LayoutAuth>
			<LoginHeadline />
			<LoginButton />
		</LayoutAuth>
	)
}
